<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="primary">查詢</mdb-card-header>
            <mdb-card-body class="pt-1">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="收件分店"
                                    placeholder="選擇收件分店"
                                    @getValue="val=>{search.id_store=val;}"
                                    v-model="storeList"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="收件日期(起)"
                                    v-model="search.date_start"></datepicker>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="收件日期(迄)"
                                    v-model="search.date_end"></datepicker>
                    </mdb-col>
                    <mdb-col md="3"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="search.page = 1;search_data()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <table class="table table-striped rwd-table-dispersion">
                    <thead>
                        <tr>
                            <th style="width:3rem">#</th>
                            <th style="width:7rem">收件日期</th>
                            <th style="width:7rem">收件分店</th>
                            <th>客戶姓名</th>
                            <th style="width:6rem">項目數量</th>
                            <th style="width:8rem">填單人</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s,sk) in repair_estimateList"
                            :key="`p_${sk}`">
                            <td data-title="#">
                                {{ (sk+1)+(data.nowPage-1)*10 }}
                            </td>
                            <td data-title="收件日期">
                                {{ s.ctime.split(' ')[0] }}
                            </td>
                            <td data-title="收件分店">
                                {{ storeKeyList[s.id_store] ? storeKeyList[s.id_store].name : '' }}
                            </td>
                            <td data-title="客戶姓名">
                                {{ s.name }}
                            </td>
                            <td data-title="項目數量">
                                {{ s.item_list.length }} 項
                            </td>
                            <td data-title="收件人">
                                {{ adminKeyList[s.id_admin] ? adminKeyList[s.id_admin].name : '' }}
                            </td>
                            <td>
                                <mdb-btn color="grey darken-1"
                                         size="sm"
                                         @click="$router.push(`/repair/estimate/view/${s.id}`)">
                                    <i class="fa fa-eye mr-1"></i>
                                    檢視
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6"
                                v-show="repair_estimateList.length==0">無資料</td>
                        </tr>
                    </tbody>
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;search_data();}" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSelect,
} from "mdbvue";
import datepicker from "../../components/datepicker";
import page from "../../components/page";
export default {
  props: {
    query: {
      default() {
        return {};
      },
    },
  },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSelect,
    datepicker,
    page,
  },
  data() {
    return {
      search: {
        id_store: "",
        date_start: "",
        date_end: "",
        page: 1,
      },
      search_change: false,
      data: {
        nowPage: 1,
        totalPage: 0,
      },
      adminKeyList: {},
      storeList: [],
      storeKeyList: [],
      repair_estimateList: [],
    };
  },
  mounted() {
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      switch (i) {
        default:
          vue.search[i] = vue.query[i] ? vue.query[i] : "";
          break;
      }
    }
    console.clear();
    vue.get_data();
  },
  watch: {
    search: {
      handler() {
        this.search_change = true;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    // 搜尋資料
    search_data() {
      let vue = this;
      if (vue.search_change) {
        let url = vue.$route.path + "?";
        for (let k in vue.search) {
          url += `${k}=${vue.search[k]}&`;
        }
        vue.$router.push(url);
        vue.$nextTick(() => {
          vue.search_change = false;
        });
      }
      vue.get_data();
    },
    //
    get_data() {
      let vue = this;
      let query = [
        {
          name: "admin",
          data: {},
        },
        {
          name: "store",
          data: {
            active: {
              type: 0,
              value: "1",
            },
          },
          sort: {
            name: 0,
          },
        },
      ];
      // 搜尋資料
      let obj = {
        name: "repair_estimate",
        data: {},
        sort: { day: 1 },
        pg: vue.search.page == "" ? 1 : vue.search.page,
      };
      if (vue.search.id_store != "") {
        obj.data.id_store = {
          type: 0,
          value: vue.search.id_store,
        };
      }
      if (vue.search.date_start != "" && vue.search.date_end != "") {
        obj.data.day = {
          type: 7,
          value: [
            vue.search.date_start + " 00:00:00",
            vue.search.date_end + " 23:59:59",
          ],
        };
      } else if (vue.search.date_start != "") {
        obj.data.day = {
          type: 5,
          value: vue.search.date_start,
        };
      } else if (vue.search.date_end != "") {
        obj.data.day = {
          type: 4,
          value: vue.search.date_end,
        };
      }
      query.push(obj);
      query.push(Object.assign({ num: 1 }, obj));
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${encodeURI(JSON.stringify(query))}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            // 頁碼
            vue.data.totalPage = Math.ceil(res.data.repair_estimateNumber / 10);
            vue.data.nowPage = vue.search.page ? parseInt(vue.search.page) : 1;
            if (res.data.repair_estimateList) {
              vue.repair_estimateList = res.data.repair_estimateList.map(
                (item) => {
                  item.item_list = JSON.parse(item.item_list);
                  return item;
                }
              );
            }
            // 人員
            res.data.adminList.forEach((item) => {
              vue.adminKeyList[item.id] = item;
            });
            // 分店資料
            vue.search.id_store = vue.$store.state.id_store;
            res.data.storeList.forEach((item) => {
              vue.storeList.push({
                text: item.name,
                value: item.id,
                selected: item.id == vue.search.id_store,
              });
              vue.storeKeyList[item.id] = item;
            });
          }
        });
    },
  },
};
</script>